export const getClientContractSearchBody = () => {
  const criterion = [];
  return {
    intent: '',
    fields: [
      'ClientOrgId',
      'EffectiveDate',
      'IsTPIRSubscribed',
      'TPIRVendorLimit',
      'TPIROutreachLimit',
      'IsCORLCleared',
      'AssessmentPurchasedLimit',
      'AssessmentLaneLimit',
      'ReservedLaneLimit',
      'ExpeditedAssessmentLimit',
      'BatchAssessmentLoadLimit',
      'IsPreAssessmentAllowed',
      'ContractIndustryId',
      'SharepointURL',
      'ContactFirstName',
      'ContactLastName',
      'ContactEmail',
      'CyberRiskScore',
    ],
    criterion: criterion,
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const searchContractSubscriptionDetailsBody = () => {
  return {
    intent: '',
    fields: [
      'ClientContractId',
      'ClientOrgId',
      'EffectiveDate',
      'IsTPIRSubscribed',
      'TPIRVendorLimit',
      'TPIROutreachLimit',
      'IsCORLCleared',
      'AssessmentPurchasedLimit',
      'AssessmentLaneLimit',
      'ReservedLaneLimit',
      'ExpeditedAssessmentLimit',
      'BatchAssessmentLoadLimit',
      'IsPreAssessmentAllowed',
      'ContractIndustryId',
      'SharepointURL',
      'ContactFirstName',
      'ContactLastName',
      'ContactEmail',
      'ContractIndustry',
      'StakeHolderPortal',
      'ExecutiveSummary',
      'CyberRiskScore',
      'VendorCollaborationScore',
      'ClientVendorInventory',
      'IsRemediationEnabled',
      'RemediationPreferences',
      'ReviewCorlRemRecommendationId',
      'AffordanceForAlternateRemGuidanceId',
      'ValidationPreferencesId',
      'LaneType',
      'GRCApiIntegrationId',
      'AssessmentLaneLimitForAudit',
      'ScopingQuestionnaireTemplateList',
      'SecurityQuestionnaireTemplateList',
      'SupplementalQuestionnaireTemplateList',
      'AllowSecCert',
      'AcceptedSecCertTypes',
      'ProgramMetrics',
      'RiskRatingRemediationPreferences',
    ],
    Criterion: [],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};
