import { format } from 'date-fns';
import isNil from 'lodash/isNil';

export const convertToUTCDate = (localDate) => {
  localDate += 'T23:59:00';
  const utcDate = new Date(new Date(localDate).toUTCString());
  return utcDate;
};

export const convertToUTCDateTime = (localDateTime) => {
  const utcDateTime = new Date(new Date(localDateTime).toUTCString());
  return utcDateTime;
};

export const convertToDateTime = (utcDateTimeAsStr, dateFormat) => {
  if (isNil(utcDateTimeAsStr)) return '';
  const localDateTime = new Date(parseInt(utcDateTimeAsStr,10));
  const formattedDateTime = format(localDateTime, dateFormat);
  return formattedDateTime;
};

export const convertToLocalDate = (utcDateTimeAsStr, dateFormat) => {
  let dateOnly = utcDateTimeAsStr.split('T')[0] + 'T00:00:00';
  const localDateTime = new Date(`${dateOnly}` + 'Z');
  const formattedDateTime = format(localDateTime, dateFormat);
  return formattedDateTime;
};

export const convertToLocalTime = (utcDateTimeAsStr) => {
  const localDateTime = new Date(`${utcDateTimeAsStr}` + 'Z');
  const formattedDateTime = format(localDateTime, 'MM/dd/yy');
  return formattedDateTime;
};

export const convertToLocalDateTime = (utcDateTimeAsStr, dateFormat) => {
  const localDateTime = new Date(`${utcDateTimeAsStr}` + 'Z');
  const formattedDateTime = format(localDateTime, dateFormat);
  return formattedDateTime;
};
