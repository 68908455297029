import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { datadogRum } from '@datadog/browser-rum';
import { accessDeniedStyles } from './styles';
import { useSelector } from 'react-redux';
import { datadogLogs } from '@datadog/browser-logs'

const AccessDenied = () => {
  const details = useSelector((state) => state.session.details);
  const userClaimList = useSelector((state) => state?.session?.details?.FeatureActionClaimList);

  useEffect(() => {
    const referrer =  document.referrer || 'No referrer';
    datadogRum.addAction('access_denied_viewed', {
      url: window.location.href,
      userId: details.UserId,
      referrer: referrer
    });
    datadogLogs.logger.error("Access Denied", {
      event: "Access Denied",
      message: "User attempted to access a restricted page",
      url: window.location.href,
      userId: details.UserId,
      userClaimList: userClaimList
    });
    if (window.pendo) {
      window.pendo.track('Access Denied Page Viewed', {
        userId: details.UserId,
        page: window.location.href,
      });
    }
  }, [details]);

  return (
    <Box sx={accessDeniedStyles}>
      <Typography variant="h2">You don't have permission to view this page</Typography>
    </Box>
  );
};

export default AccessDenied;
