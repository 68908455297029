import { Backdrop } from '@mui/material';
import React from 'react';
import loadingImg from '../assets/images/corl-logo-loading.png';
import { loaderBackDropStyle } from './TPIRVendors/TPIRVendorsStyles';

const Loading = () => (
  <div data-testid="loadingIcon">
    <Backdrop sx={(theme) => loaderBackDropStyle(theme)} open={true}>
      <div className="loaderPosition">
        <div className="spinner"></div>
        <div className="loadImg">
          <img width={60} src={loadingImg} alt="Loading..." />
        </div>
      </div>
    </Backdrop>
  </div>
);

export default Loading;
